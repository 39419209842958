import { render, staticRenderFns } from "./im-layout.vue?vue&type=template&id=26cf9214&scoped=true&"
import script from "./im-layout.vue?vue&type=script&lang=js&"
export * from "./im-layout.vue?vue&type=script&lang=js&"
import style0 from "./im-layout.vue?vue&type=style&index=0&id=26cf9214&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26cf9214",
  null
  
)

export default component.exports